import React, {useEffect, useState} from "react";
import { useSelector , useDispatch} from "react-redux";
import { useNavigate, createSearchParams } from "react-router-dom";
import { selectAllRequests } from "../../features/audiences/audienceSlice";
import { saveDirectory } from "../../features/directory/directorySlice";
import { saveCurrentView } from "../../features/currentView/currentViewSlice";
import { selectUserRole, selectOrgId} from "../../features/auth/authSlice";
import {formatUserName} from "../format"
import {  
  useActivatePlatformMutation,
  useUpdateAudienceStatusMutation, 
  useSendEmailMutation,
  useGetConfigurableValueQuery,
  useGetAllRequestsSummaryPerPlatformQuery,
  useDeletePlatformRequestMutation,
  useGetCountExportRequestsAllQuery,
  useGetPresignedUrlMutation
 } from "../../app/api";
import { formatSelectedFiltersActivations, capitalizeFirstLetter} from "../userSettings";
import { numberWithCommas, formatAudienceTitleForActivationsList } from "../userSettings";
import { useGetSelectAllAudiencesQuery, useGetAllSalesQuery, useOrgListQuery} from "../../app/api";
import { liveRampPlatforms } from "../../data/ActivateData";
import { isPermissionsRO, selectUserId } from "../../features/auth/authSlice";
import { save } from "../../features/auth/roleSlice";

export default function Activations() {

  const userId = useSelector(selectUserId);
  const hasROpermissions = useSelector(isPermissionsRO);
  const requests = useSelector(selectAllRequests);
  const dispatch = useDispatch();
  //const [tabView, setTabView] = useState('audience');
  const [ hideNotReady, setHideNotReady ] = useState(false);
  const navigate = useNavigate();
  const tabView = 'audience';
  const [ updateAudienceStatus ] = useUpdateAudienceStatusMutation();
  const [ activatePlatformCall ] = useActivatePlatformMutation();
  const [ activatePlatformObj, setActivatePlatformObj ] = useState({});
  const [ audienceListData, setAudienceListData] = useState([]);
  const { data: audienceData, isSuccess: audienceSuccess } =  useGetSelectAllAudiencesQuery();
  const [ sales, setSales] = useState([]);
  const { data: salesData, isSuccess: salesDataSuccess } = useGetAllSalesQuery(); 
  const { data: orgList } =  useOrgListQuery();
  const [sendEmail] = useSendEmailMutation();
  const {data: allExportRequests, refetch: refetchAllExportRequests} = useGetCountExportRequestsAllQuery(); 
  const {data: allRequests, refetch: refetchAllRequests} = useGetAllRequestsSummaryPerPlatformQuery();
  const {data: newActivationEmailRecipient} = useGetConfigurableValueQuery({configurable_name: 'email.new_activation.recipient'});
  const userRole = useSelector(selectUserRole);
  const [ deletePlatformRequestObj, setDeletePlatformRequestObj] = useState();
  const [ deletePlatformRequest, {isSuccess:deleteRequestSuccess} ] = useDeletePlatformRequestMutation();
  const [ getPresignedUrl ] = useGetPresignedUrlMutation();
  console.log(`deleteRequestSuccess: ${deleteRequestSuccess}`);
  const filterByOrgId = userRole == "ADMIN";
  const userOrgId = useSelector(selectOrgId);

  useEffect( () => {
    if (audienceSuccess) {
      setAudienceListData(audienceData);
    }
  }, [audienceSuccess] );

  useEffect( () => {
    if (salesData) {
      setSales(salesData);
    }}
    , [salesDataSuccess]);     

  
  const activateActivationDistro = (refNo) => {
    dispatch(saveCurrentView({type:'activationReferenceNo', value: refNo }));
    dispatch(saveDirectory({type:'adminPage', value:'activationDistro'}));
  }

  const deletePlatform = (platformRequest) => {
    deletePlatformRequest({platform_id: platformRequest.platform_id})
      .then(() => {
        // Action to perform after successful deletion
        setDeletePlatformRequestObj(); 
        refetchAllRequests();
      })
      .catch((error) => {
        // Handle any errors
        console.error('Error deleting platform:', error);
      });
  }


  /**
   * Admin Dashboard; activate an Audience platform activation
   */
  const activatePlatform = () => {

    let liveramp_reference = document.getElementById('liveramp_reference_input').value;

    if (liveramp_reference) {

      activatePlatformCall({platform_id: activatePlatformObj.platform_id, segment_id: liveramp_reference});

      sendEmail({
            template_name: 'client_new_platform_activation',
            recipient_to: activatePlatformObj.contact_email,
            recipient_cc: newActivationEmailRecipient.config_value,
            subject: `Platform [${activatePlatformObj.platform_title}] was activated for Audience [${activatePlatformObj.audience_title}].`, 
            template_variables: {
              audience_title: (activatePlatformObj.audience_title || '').split('||')[0],
              platform_title: activatePlatformObj.platform_title
            }
          });
      setActivatePlatformObj({});
      refetchAllRequests();
    } else {
      console.log('Missing reference');
    }

  }

  const approveAudience = (refNo) =>  {
    updateAudienceStatus({ref_no: refNo, status_value: 'PROCESSED'});
  }

  const expireAudience = (refNo) =>  {
    updateAudienceStatus({ref_no: refNo, status_value: 'EXPIRED'});
  }

  const softDeleteAudience = async (refNo) =>  {
    await updateAudienceStatus({ref_no: refNo, status_value: 'DELETED'});
    refetchAllExportRequests();
  } 

  const activateExport = async (refNo) =>  {
    await updateAudienceStatus({ref_no: refNo, status_value: 'READY'});
    refetchAllExportRequests();
  }
  const arrayForSort = [...requests];

  const actionButton = (req) => {
    if (req) {
      if (req.request_type == 'MULTIFORMAT') { 
        switch (req.user_audience_status) {
          case 'OPEN': 
            if (!hasROpermissions && userRole=='SUPERADMIN'){
              return (
                <button onClick={()=>{activateExport(
                  req.uuid
                  )}}>ACTIVATE</button>
              )
            }
            else {
              return (
                <></>
              )
            }
          default: 
            return <></>
        }
      } 
      else {
        switch (req.platform_status) {
          case 'SUBMITTED': 
            if (!hasROpermissions && userRole=='SUPERADMIN'){
              return (
                <button className="activate" onClick={()=>{setActivatePlatformObj({
                  platform_id: req.platform_id, 
                  audience_title: req.title, 
                  platform_title: req.platform_name,
                  contact_email: req.contact_email})}}>ACTIVATE</button>
              )
            }
            else {
              return (
                <></>
              )
            }
          default: 
            return <></>
        }
      }
    }
  }

  const changeOverride = () => {
    dispatch(save({type: 'override', value: "YES"}));
  }

  const buildPlatformLauncher = (req) => {
    if (req) {
      if (req.request_type && req.request_type != 'MULTIFORMAT') {
        if (determineRecordStatus(req) == 'Expired'){
          if (userId != req.user_id){ // This audience was generated by a different user. Run UserID override for activation checkout. 
            return (
              <button className='platforms' onClick={() =>  {changeOverride(); navigate({pathname:'/dashboard/audience/activate', search: createSearchParams({uuid: req.uuid, ref: req.reference_id, custom: req.custom, override_id: req.user_id}).toString()})}}><p>Add Platform</p></button>
            )
          } else {
            return ( // This audience was generated by current user. 
              <button className='platforms' onClick={() =>  {changeOverride(); navigate({pathname:'/dashboard/audience/activate', search: createSearchParams({uuid: req.uuid, ref: req.reference_id, custom: req.custom}).toString()})}}><p>Add Platform</p></button>
            )
          }
        }
      }
      else {
        if (req.s3_path) {
          return (
            <> 
              <button className='exports' onClick={() => {downloadExport(req.s3_path, req.reference_id)}}><p>Download</p></button>
            </>      
          )
        }
      }
    }
  }

  /**
   * TODO: This function seems to be outdated?  References req.status instead of req.user_audience_status
   */
  const button = (req , type) => {
    if (req.status == 'OPEN') {
      if (type == 'distro') {
        return (
          <td><button onClick={()=>{activateActivationDistro(req.reference_id)}}>ACTIVATE</button></td>
        )
      }
      else {
        return (
          <td><button onClick={()=>{approveAudience(req.reference_id)}}>APPROVE</button></td>
        )
      }
    }
    else {
      if (type == 'distro') {
        return (
          <td><button className='disabled'>PROCESSED</button></td>
        )
      }
      else {
        if (req.status == 'EXPIRED'){
          return (
            <td><button className='disabled' onClick={()=>{expireAudience(req.reference_id)}}>EXPIRED</button></td>
          )
        }
        else {
          return (
            <td><button className='blue' onClick={()=>{expireAudience(req.reference_id)}}>EXPIRE</button></td>
          )
        }
      }
    }
  }

  const downloadExport = async (s3_path, refNo) => {
    const download = await getPresignedUrl({s3_path: s3_path});
    console.log('S3 presigned is:', download);
    window.open(download.data, "_blank");
    await updateAudienceStatus({ref_no: refNo, status_value: 'DOWNLOADED'});
  }

  const activatePopup = () => {
    if (Object.keys(activatePlatformObj).length > 0) {
      return (
        <div className='popup_overlay expanded'>
          <div className='popup_widget'>
            <button id='fa-times' className='fa fa-times close' onClick={()=>{setActivatePlatformObj({})}}/>
            <div className='centered'>
              <h3> Activate Audience </h3>
              <p> Please enter the reference number to activate <q>{activatePlatformObj.audience_title}</q> on {activatePlatformObj.platform_title}</p>
              <input id="liveramp_reference_input" type="text" autoFocus />
              <button onClick={()=>{activatePlatform()}}> ACTIVATE </button>
            </div>
          </div>
        </div>
      )
    }
  }

  const deletePopup = () => {
    if (deletePlatformRequestObj) {
      return (
        <div className='popup_overlay expanded'>
          <div className='popup_widget'>
            <button id='fa-times' className='fa fa-times close' onClick={()=>{setDeletePlatformRequestObj()}}/>
            <div className='centered'>
              <h3> Delete {deletePlatformRequestObj.request_type == 'MULTIFORMAT'? 'Export' : 'Platform'} Request </h3>
              <p> Are you sure you want to delete {deletePlatformRequestObj.request_type == 'MULTIFORMAT'? 'Export' : 'Activation'} Request with </p>
              <p> Ref ID: {deletePlatformRequestObj.reference_id}?</p>
              <div className='popup_submit_wrapper'>
                <button onClick={()=>{deletePlatformRequestObj.request_type == 'MULTIFORMAT'?  softDeleteAudience(deletePlatformRequestObj.reference_id) : deletePlatform(deletePlatformRequestObj); setDeletePlatformRequestObj()}}> Delete </button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const formatDateRange = (req) => {
    if (req) {
      if (req.start_date && req.end_date){
        return getFormattedDate(req.start_date) + "-" + getFormattedDate(req.end_date);
      }
    }
  }

  const distroResults = () => { 
    return (
      arrayForSort 
      .filter(t => t.request_type == 'DISTRIBUTION')
      .map((req, i) => (
        <tr key={i}>
          {button(req, 'distro')}
          <td>{req.reference_id}</td>
          <td>{req.title}</td>
          <td>{formatUserName(req)}</td>
          <td>{req.platform}</td>
          <td>{req.status}</td>
          <td><a href='#'>Assign to me</a></td>
        </tr>
      ))
    )
  }

  const determineDeleteType = (req) => {
    if (req) {
      if ( req.request_type == 'MULTIFORMAT') { 
        return (
          <i className='fa-solid fa-trash hover-point' id={req.user_audience_id + "_delete"} onClick={()=>{setDeletePlatformRequestObj( req )}}> </i>
        )
      }
      else {
        return (
          <i className='fa-solid fa-trash hover-point' id={req.platform_id + "_delete"} onClick={()=>{setDeletePlatformRequestObj( req )}}> </i>
        )
      }
    }
  }

  const audienceResults = () => { 
    let activationsAndExports = [];
    activationsAndExports = activationsAndExports.concat(allRequests);
    activationsAndExports = activationsAndExports.concat(allExportRequests);
    if (filterByOrgId) {
      activationsAndExports = activationsAndExports?.filter((t) => t.org_id == userOrgId);
    }
    if (activationsAndExports) {
      return (
        activationsAndExports
        .sort((a,b) => b.updated.localeCompare(a.updated))
        .map((req, i) => (
          <tr key={i}>
            {userRole == "SUPERADMIN"? <td>{actionButton(req)}</td> : ''}
            <td className="titles">{formatOriginalAudienceTitle(req)}</td>
            <td className="titles">{formatAudienceTitleForActivationsList(req?.title)}</td>
            <td>{determineRecordStatus(req)}</td>
            <td>{numberWithCommas(req?.records_uploaded)}</td>
            <td>{platformImage(liveRampPlatforms, req)}</td>
            <td>{req?.account_id}</td>
            <td className='popup_static_width'>{formatFilters(req?.filter_query)}</td>
            <td className="extra_room">{formatDateRange(req)}</td>
            <td>${formatBillingPrice(req)}</td>
            <td>${numberWithCommas(formatTotalPrice(req, sales))}</td>
            <td>{ new Date(req?.created).toLocaleDateString() }</td>
            <td>{formatOrg(req)}</td>
            <td>{req?.account_name}</td>
            <td>{req?.reference_id}</td>
            <td className='requestor no_capitalization'><p>{formatRequestor(req)}</p></td>
            <td>{buildPlatformLauncher(req)} {determineDeleteType(req)}</td>
          </tr>
        ))
      )
    }
  }

  const audienceResultsReadyOnly = () => {  
    let allRequestsReadyOnly = allRequests;
    if (filterByOrgId) {
      allRequestsReadyOnly = allRequestsReadyOnly?.filter((t) => t.org_id == userOrgId);
    }
    return (
      allRequestsReadyOnly
      .filter(r => r.platform_status == 'SUBMITTED')
      .map((req, i) => (
        <tr key={i}>
          {userRole == "SUPERADMIN"? <td>{actionButton(req)}</td> : ''}
          <td className="titles">{formatOriginalAudienceTitle(req)}</td>
          <td className="titles">{formatAudienceTitleForActivationsList(req?.title)}</td>
          <td>{determineRecordStatus(req)}</td>
          <td>{numberWithCommas(req.records_uploaded)}</td>
          <td>{platformImage(liveRampPlatforms, req)}</td>
          <td>{req.account_id}</td>
          <td className='popup_static_width'>{formatFilters(req.filter_query)}</td>
          <td className="extra_room">{formatDateRange(req)}</td>
          <td>${formatBillingPrice(req)}</td>
          <td>${numberWithCommas(formatTotalPrice(req, sales))}</td>
          <td>{ new Date(req.created).toLocaleDateString() }</td>
          <td>{formatOrg(req)}</td>
          <td>{req.account_name}</td>
          <td>{req.reference_id}</td>
          <td className='requestor no_capitalization'><p>{formatRequestor(req)}</p></td>
          <td>{buildPlatformLauncher(req)} {determineDeleteType(req)}</td>
        </tr>
      ))
    )
  }

  const expiredAudienceResults = () => { 
    return (
      arrayForSort 
      .filter(t => t.request_type == 'CUSTOM_AUDIENCE')
      .filter(t => t.status == 'EXPIRED')
      .map((req, i) => (
        <tr key={i}>
          <td></td>
          {button(req, 'audience')}
          <td>{req.reference_id}</td>
          <td>{req.title}</td>
          <td>{formatUserName(req)}</td>
          <td>{req.status}</td>
          <td><a href='#'>Assign to me</a></td>
        </tr>
      ))
    )
  }


 /* const tabClass = (thisTab) => {
    if (thisTab == tabView) {
      return 'fifth-page centered';
    }
    else {
      return 'fifth-page centered disabled';
    }
  }*/

  const determineRecordStatus = (req) => {
    if (req) { 
      if ( req?.request_type == 'MULTIFORMAT') {
        if (req?.user_audience_status == 'OPEN') {
          return 'Pending';
        }
        else {
          return capitalizeFirstLetter(req.user_audience_status);
        }
      } 
      else {
        let current_datetime = new Date();
        if (req?.end_date ) {
          if (new Date(req.end_date) > current_datetime ) {
            return capitalizeFirstLetter(req.platform_status);
          }
          else {
            return 'Expired';
          }
        }
      }
    }
  }

  const formatFilters = (filter_query) => {
    if (filter_query) {
      let filtersFormatted = formatSelectedFiltersActivations(filter_query);
      if (filtersFormatted.length > 25) {
        return (
          <div className='filterPopup'>
            <div className='staticFilters'>{filtersFormatted.substring(0,24)}</div>
            <i className='fa fa-solid fa-info-circle'></i>
            <div className='filterPopupWrapper'>
              <p className='filter_title'>Audience Filters</p>
              <p>{filtersFormatted}</p>
            </div>
          </div>
        )
      }
      else {
        return (
          <p>{filtersFormatted}</p>
        )
      }
    }
  }

  const formatOriginalAudienceTitle = (req) => {
    if (req && audienceListData) {
      const audienceMatch = audienceListData.find(t => t.audience == req.audience);
      if (!audienceMatch){
        return (<></>)
      }
      else {
        return (
          <>
            <p>{audienceMatch.title}</p>
          </>
        )
      }
    }
  }

  const getFormattedDate = (dateString) => {
    if (dateString) {
      let date = new Date(dateString);
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      return month + '/' + day + '/' + year;
    }
  }

  const formatBillingPrice = (req) => {
    if (req) {
      if (req.request_type == 'MULTIFORMAT'){
        return req.cost?.toFixed(2);
      }
      else {
        return req.audience_cpm?.toFixed(2);
      }
    }
  }


  const formatTotalPrice = (req, sales) => {
    if (req && sales) {
      if (req.request_type == 'MULTIFORMAT'){
        const costTotal = sales.find(t => t.user_audience_reference_id == req.reference_id);
        if (costTotal) {
          return costTotal.total.toFixed(2);
        }
      }
      else {
        const costTotal = sales.find(t => t.platform_activation_id == req.platform_id);
        if (costTotal) {
          return costTotal.total.toFixed(2);
        }
      }
    }
  }

  const formatOrg = (req) => {
    if (req && orgList) {
      if (req?.org_id) {
        const org = orgList.find(t => t.id == req.org_id);
        if (org) {
          return org.name;
        }
      }
    }
  }

  const formatRequestor = (req) => {
    if (req) {
      if ( req?.first_name || req?.last_name || req?.username) {
        if (req.first_name || req.last_name) { // Format name for requestor
          if (req.first_name && req.last_name) {
            return capitalizeFirstLetter(req.first_name) + ' ' + capitalizeFirstLetter(req.last_name);
          }
          else {
            if (req.first_name) {
              return capitalizeFirstLetter(req.first_name);
            }
            if (req.last_name) {
              return capitalizeFirstLetter(req.last_name);
            }
          }
        }
        else { // No name, display username
          return req.username.toLowerCase();
        }
      }
    }
  }

  const platformImage = (liveRampPlatforms, req) => {    
    if (req && liveRampPlatforms) {
      if ( req.request_type == 'MULTIFORMAT') {
        let formattedExportTitleArray = req.title.split('-');
        if (formattedExportTitleArray) {
          if (formattedExportTitleArray[formattedExportTitleArray.length - 1].trim() == 'ids') {
            return "LALIDS";
          }
          else {
            return (
              <>
                {formattedExportTitleArray[formattedExportTitleArray.length - 1].trim()}
              </>
            )
          }
        } 
      }
      else {
        const thisPlatform = liveRampPlatforms.find(t => t.id == req.user_platform_id);
        if (!thisPlatform){
          return (<></>)
        }
        else {
          return (
            <>
              <img className={thisPlatform.text == 'X'? 'platform_x' : '' } src={"../../images/activation/"+thisPlatform.image}/>
            </>
          )
        }
      }
    }
  }

  const platformImages = (liveRampPlatforms) => {
    /* Pre-loading images to cache - keep hidden */
    if (liveRampPlatforms) {
      return (
        <div className='hidden'>
          {liveRampPlatforms
            .filter(t => t.active == 0)
            .sort((a, b) => a.text.localeCompare(b.text))
            .map((h, i) => (
              <img key={i} src={"../../images/activation/"+h.image}/>
            ))
          }
        </div>
      )
    }
  }
  

  const tabViewScreen = () => {
    switch(tabView){
      case 'distro':
        return (
          <>
           <table>
             <thead>
               <tr>
                 <th>Name</th>
                 <th>Requestor</th>
                 <th>Platform</th>
                 <th>Status</th>
                 <th>Rep</th>
               </tr>
             </thead>
             <tbody>
               {!hasROpermissions && distroResults()}
             </tbody>
           </table>
          </>
        )
      case 'audience':
        return (
          <>
            <table className='activationExportTable'>
              <thead>
                <tr>
                  {userRole == "SUPERADMIN"? <th className='admin'>Activate</th> : ''}
                  <th className={userRole != "SUPERADMIN"? 'titles admin_no_activate': 'titles'}>Audience</th> 
                  <th className='titles'><p>Custom Name</p></th>
                  <th>Status</th> 
                  <th><p>Record Count</p></th>
                  <th><p>Platform / Download</p></th>
                  <th><p>Account ID</p></th>
                  <th className='popup_static_width'><p>Audience Filters</p></th>
                  <th className="extra_room">Campaign Dates</th>
                  <th>Billed Rate</th>
                  <th>Total Cost</th>
                  <th><p>Transaction Date</p></th>
                  <th>Organization</th>
                  <th>Account</th>
                  <th>Ref ID</th>
                  <th className='requestor'>Requestor</th>
                  <th className={userRole == "SUPERADMIN"? 'super_admin' : ''}></th>
               </tr>
             </thead>
             <tbody>
              <div className='hidden'>
                {platformImages(liveRampPlatforms)}
              </div>
               {allRequests && !hideNotReady && audienceResults()} 
               {allRequests && hideNotReady && audienceResultsReadyOnly()} 
               {expiredAudienceResults()} 
             </tbody>
           </table>
          </>
        )
    }
  }

  const audienceCount = filterByOrgId? allRequests?.filter((t) => t.org_id == userOrgId && t.platform_status == 'SUBMITTED').length : allRequests?.filter(t => t.platform_status == 'SUBMITTED').length;

  return (
    <div className='activate-form' >
      {!hasROpermissions && activatePopup()}
      {!hasROpermissions && deletePopup()}
      <div className='admin-list-wrapper' style={{width: 'unset'}}>
        <div className='admin-list' style={{width: 'unset'}}>
          <h1> Activation Requests </h1>
          <h3>{audienceCount} Requests Ready to Activate</h3>
          <p className="green">{deleteRequestSuccess && <>Request Deleted</>}&nbsp;</p>
          <div className='link no-underline' onClick={() => {setHideNotReady(!hideNotReady)}}>{hideNotReady ? 'All Activations' : ' Pending Activations'} </div>
          <br/>
          {tabViewScreen()}
        </div>
      </div>
    </div>
  )
}