import React, { useState, useEffect } from "react";
import { useAddOrganizationMutation } from "../../app/api";

export default function NewOrganization() {

  const [organizationName, setOrganizationName] = useState('');
  const [organizationErrorMessage, setOrganizationErrorMessage] = useState('');
  const [addOrganization, { isSuccess: organizationAdded, isError: organizationError, error }] = useAddOrganizationMutation();
  
  useEffect(() => {
    setOrganizationErrorMessage('');
    if (organizationError) {
      setOrganizationErrorMessage('Error: ' + JSON.stringify(error.data.detail.split('"')[1]));
    }
  }, [organizationError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (organizationName) {
      addOrganization({ org_name: organizationName });
    } else {
      setOrganizationErrorMessage('Please enter a name');
    }
    setOrganizationName('');
  };

  return (
    <div style={{margin: '30px'}}>
      <form onSubmit={handleSubmit}>
        <label>
          Organization Name:
          <input
            type="text"
            value={organizationName}
            onChange={(event) => setOrganizationName(event.target.value)}
          />
        </label>
        <button type="submit">Submit</button>
      </form>
      {organizationAdded && <p>Organization Added</p>}
      {organizationErrorMessage && <p style={{color: 'red'}}>{organizationErrorMessage}</p>}
    </div>
  )
}

