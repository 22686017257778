import React, { useState } from "react";
import { useLoginMutation, useLoginAdminMutation, useUpdateUserIsInvitedMutation } from "../../app/api";
import { saveCredentials } from "./authSlice";
import LoginError from "./LoginError";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectLogoutMessage, saveCurrentView } from "../currentView/currentViewSlice";
import { useDispatch } from "react-redux";
import { saveDirectory } from "../../features/directory/directorySlice";
import { save } from "../../features/auth/roleSlice";

export default function LoginForm({loginType}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");


  const logoutMessage = useSelector(selectLogoutMessage);

  const [errorStatusCode, setErrorStatusCode] = useState(undefined);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const navigate = useNavigate();
  const [login, { isLoading, isError }] = useLoginMutation();
  const [loginAdmin,  {isLoading: isLoadingAdmin, isError: isErrorAdmin }] = useLoginAdminMutation();
  const [updateUserIsInvited] = useUpdateUserIsInvitedMutation();

  const canLogin = !isLoading;// && username.length > 0 && password.length > 0;
  const canLoginAdmin = !isLoadingAdmin && username.length > 0 && password.length > 0;
  const queryParameters = new URLSearchParams(window.location.search);
  const message = queryParameters.get("message") || logoutMessage;

  /**
   * Validate the email address entered
   */
  const validEmail = () => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return username.match(validRegex);
  }

  const validPassword = () => {
    return password.length > 0;
  }

  const dispatch = useDispatch();
  
  async function onLogin(event) {
    event.preventDefault();
    if (validEmail() && validPassword()) { 
      console.log(`email and password are valid`);
      try {
        // Prevent the form submission
        var result;
        // Submit the login attempt
        if (loginType === 'ADMIN') {
          result = await loginAdmin({ username, password }).unwrap();
        }
        else {
          result = await login({ username, password }).unwrap();
        }

        // Reached here when the request is successful. The access token and
        // username have been updated in the local state by authSlice.

        // Save the access token and username to local storage
        saveCredentials(result.access_token, username, result?.user?.userId, result?.user?.userRole, result?.user?.companyName, result?.user?.orgId, result?.user?.permissions);
        if (loginType == 'ADMIN') {
          navigate('/'); 
        }
        else {
          /** Check if user is invited, if so, redirect to dashboard Settings page for them to complete their account setup */
          if (result?.user?.isInvited == 1) {
            updateUserIsInvited({ userId: result?.user?.userId, isInvited: 0 }).unwrap().then(() => {
              dispatch(save({type: 'override', value: "YES"}));
              dispatch(save({type: 'firstLogin', value: "YES"}));
              dispatch(saveDirectory({type:'clientPage', value:'setting'}));
              navigate('/dashboard');
            });
          } else {
            navigate('/dashboard'); 
          }
        }
      } catch (err) {
        // Set the error status code, if available, for the LoginError component
        setErrorStatusCode(err.status);
      }
    } else {
      setErrorStatus(!validEmail() ? 'Please enter a valid email address' : 'Please enter a valid Password');
    }
  }

  const loginFields = () => {
    if (loginType == 'ADMIN') {
      return (
        <>
          <button type="submit" onClick={onLogin} disabled={!canLoginAdmin}>
            Login Admin
          </button>
          {isLoadingAdmin && <p className='centered'>( Spinning ... )</p>}
          {isErrorAdmin && errorStatusCode && (
            <LoginError errorStatusCode={errorStatusCode} />
          )}
        </>
      )
    }
    else {
      return (
        <div className='submit_wrapper'>
          <button type="submit" onClick={(e) => {dispatch(saveCurrentView({type: 'logoutMessage', value: ''})); onLogin(e);}} disabled={!canLogin}>
            <p>Log in</p>
          </button>
          <div className='auth-feedback'>
            {isLoading && <p className='centered'><span className='fa fa-spinner'></span></p>}
            {isError && errorStatusCode && (
              <LoginError errorStatusCode={errorStatusCode} />
            )}
            {errorStatus && 
              <p className='centered'>
                {errorStatus}
              </p>
            }
          </div>
        </div>
      )
    }
  }

  /*
  const forgotOrNot = () => {
    if (forgot) {
      return (
        <ForgotPasswordForm callback={setForgot}/>
      )
    }
    else {
      return (
        <div>
          <div className='auth-message'>
            {message && <p>{message}</p>}
          </div>
          <form className='auth-form'>
            <h2>Log In</h2>
            <input
              type="text"
              autoComplete="username"
              placeholder="Email *"
              value={username}
              onChange={(e) => {
                setErrorStatus(undefined); 
                setErrorStatusCode(undefined); 
                setUsername(e.target.value)
              }}
            />
            <br/>
            <input
              type="password"
              autoComplete="current-password"
              placeholder="Password *"
              value={password}
              onChange={(e) => {
                setErrorStatus(undefined); 
                setErrorStatusCode(undefined); 
                setPassword(e.target.value)
              }}
            />
          
            <div>
              {loginFields()}
              <br/>
              <div className='forgot-div'>
                <p className='auth-link' onClick={()=>{navigate('/signup')}}>I am A New User</p>
                <p onClick={()=> {setForgot(true)}} className='auth-link'>I Forgot My Password</p>
              </div>
            </div>
          </form>
        </div>
      )
    }
  } 
*/

  return (
    <div className="landing">
      <div className={ message? 'auth-message' : 'hidden' }>
        { message && <p>{message}</p> }
      </div>
      <form className='auth-form'>
        <div className='landing_wrapper'>
          <h2>Log In</h2>
          <div className='login_entry_wrapper'>
            <input
              type="text"
              autoComplete="username"
              placeholder="Email *"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className='login_entry_wrapper'>
            <input
              type="password"
              autoComplete="current-password"
              placeholder="Password *"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            {loginFields()}
            <br />
            <div className='forgot-div'>
              <p className='auth-link' onClick={() => { navigate('/signup') }}>CREATE ACCOUNT</p>
              <p onClick={() => { navigate('/forgot-password') }} className='auth-link'>FORGOT PASSWORD?</p>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

LoginForm.propTypes = {
  loginType: PropTypes.string,
}