import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOpenRequests } from "../../features/audiences/audienceSlice";
import { selectPendingUsers, selectUserChange, saveLists, selectAllFeedback, selectFeedbackChange } from "../../features/adminLists/adminListsSlice";
import { saveDirectory , selectAdminPage } from "../../features/directory/directorySlice";
import { useUserListQuery, useAllFeedbackQuery } from "../../app/api";
import { save } from "../../features/auth/roleSlice";
import { logout } from "../../features/auth/authSlice";
import { selectUserName, selectUserRole, selectOrgId } from "../../features/auth/authSlice";
import { useGetUserByUsernameQuery } from "../../app/api";

export default function AdminMenu() {

  const dispatch = useDispatch();
  const openRequests = useSelector(selectOpenRequests);
  const adminPage = useSelector(selectAdminPage);
  const userOrgId = useSelector(selectOrgId);
  const pendingUsers = useSelector(selectPendingUsers);
  const pendingUsersAdmin = pendingUsers.filter((t) => t.org_id == userOrgId);
  const userChange = useSelector(selectUserChange);
  const feedbackChange = useSelector(selectFeedbackChange);
  const allFeedback = useSelector(selectAllFeedback);
  const userName = useSelector(selectUserName);
  const userRole = useSelector(selectUserRole);
  const { data: userList, loading: isLoading, refetch: refetchUsers } =  useUserListQuery(); 
  const { data: feedbackList, loading: feedbackIsLoading, refetch: refetchFeedback } =  useAllFeedbackQuery(); 
  const { data: thisUser } = useGetUserByUsernameQuery({username: userName});

  const [openRequestCount, setOpenRequestCount] = useState(0);
  const [openPendingUsersCount, setOpenPendingUsersCount] = useState(0);
  const [openFeedbackCount, setOpenFeedbackCount] = useState(0);


  useEffect(() => {
    refetchUsers() 
  }, [userChange]);

  useEffect(() => {
    refetchFeedback() 
  }, [feedbackChange]);

  useEffect(() => {
    setOpenRequestCount((openRequests||[]).length);
  }, [openRequests]);

  useEffect(() => {
    if (!isLoading) {
      setOpenPendingUsersCount((pendingUsers||[]).length);
    } 
  }, [pendingUsers]);

  useEffect(() => {
    if (!feedbackIsLoading) {
      setOpenFeedbackCount((allFeedback||[]).filter((t) => t.status == 'OPEN').length);
    } 
  }, [allFeedback]);

  useEffect(() => {
    dispatch(saveLists({type:'all-users', value: userList}));
  }, [userList]);

  useEffect(() => {
    dispatch(saveLists({type:'all-feedback', value: feedbackList}));
  }, [feedbackList]);

  const changeOverride = () => {
    dispatch(save({type: 'override', value: "YES"}));
  }
 
  const openFeedbackCountCircle = () => {
    if (openFeedbackCount > 0) {
      return (
        <div className='notification-circle'>{openFeedbackCount}</div>
      )
    }
  }

  const openRequestCountCircle = () => {
    if (openRequestCount > 0) {
      return (
        <div className='notification-circle'>{openRequestCount}</div>
      )
    }
  }

  const openPendingUsersCountCircle = () => {
    if (openPendingUsersCount > 0) {
      return (
        <div className='notification-circle'>{userRole=="SUPERADMIN"? openPendingUsersCount : pendingUsersAdmin.length}</div>
      )
    }
  }

  const isBold = (currentClass, linkPage) => {
    return (
      linkPage == adminPage ? currentClass + ' bold' : currentClass 
    )

  }

  return (
    <div className='admin-menu' key={openRequestCount}>
      <p className={isBold('','dashboard')} onClick={() =>{dispatch(saveDirectory({type:'adminPage', value: 'dashboard'}))}}>Dashboard</p>
      <div className={isBold('menu-option','activations')} onClick={() =>{dispatch(saveDirectory({type:'adminPage', value: 'activations'}))}}>Activations{openRequestCountCircle()}</div>
      <div className={isBold('hidden','menu-option','exports')} onClick={() =>{dispatch(saveDirectory({type:'adminPage', value: 'exports'}))}}>Exports</div>
      <p className={isBold('hidden','categories hidden')} onClick={() =>{dispatch(saveDirectory({type:'adminPage', value: 'categories'}))}}>Categories</p>
      {userRole == 'SUPERADMIN' && <p className={isBold('','create-audience')} onClick={() =>{dispatch(saveDirectory({type:'adminPage', value: 'create-audience'}))}}>Audiences</p>}
      <div className={isBold('menu-option','users')} onClick={() =>{dispatch(saveDirectory({type:'adminPage', value: 'users'}))}}>Users{openPendingUsersCountCircle()}</div>
      {userRole == "SUPERADMIN" && <div className={isBold('menu-option','orgs')} onClick={() =>{dispatch(saveDirectory({type:'adminPage', value: 'orgs'}))}}>Organizations</div>}
      {userRole == 'SUPERADMIN' && <div className={isBold('menu-option','feedback')} onClick={() =>{refetchFeedback(); dispatch(saveDirectory({type:'adminPage', value: 'feedback'}))}}>Feedback{openFeedbackCountCircle()}</div>}
      {thisUser?.dev == 1 && <div className={isBold('menu-option','dev')} onClick={() =>{dispatch(saveDirectory({type:'adminPage', value: 'dev'}))}}>Dev </div>}
      {thisUser?.dev == 1 && <div className={isBold('menu-option','dev-tools')} onClick={() =>{dispatch(saveDirectory({type:'adminPage', value: 'dev-tools'}))}}>Dev Tools </div>}
      {userRole.includes("ADMIN") && <p className='view-change-admin' onClick={() =>{changeOverride()}}>Client View</p>}
      <p className='menu-option' onClick={() =>{dispatch(logout())}}>Log Out</p>
    </div>
  )
}