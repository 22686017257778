import React, { useState, useEffect } from "react";
import { 
  useOrgDomainListQuery, 
  useAddDomainMutation, 
  useDeleteDomainMutation, 
  useSetOrganizationArchiveStatusMutation} from "../../app/api";
import PropTypes from "prop-types";

export default function Organization({ organization }) {

  const [newDomain, setNewDomain] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [orgToArchive, setArchiveOrg] = useState('');
  const [orgToRestore, setRestoreOrg] = useState('');

  const { data: domainList, isLoading, refetch: refetchOrgDomains } = useOrgDomainListQuery({org_id: organization.id});
  const [addDomain, {isSuccess: domainAdded}] = useAddDomainMutation();
  const [deleteDomain, {isSuccess: domainDeleted}] = useDeleteDomainMutation();
  const [setOrganizationArchiveStatus] = useSetOrganizationArchiveStatusMutation();
  const [isValidDomainName, setIsValidDomainName] = useState(true);
  const [domainErrorMessage, setDomainErrorMessage] = useState('');

  const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const toggleArchive = (org_id, show) => {
    document.getElementById('archive_' + org_id).classList.toggle('hidden', !show);
  }

  const deletePopup = () => {
    if (orgToArchive != '') {
      return (
        <div className='popup_overlay expanded'>
          <div className='popup_widget' style={{width: '500px'}}>
            <button id='fa-times' className='fa fa-times close' onClick={()=>{setArchiveOrg('')}}/>
            <div className='centered'>
              <h3> Achive Organization </h3>
              <p> Are you sure you want to archive {orgToArchive.name}?</p>
              <p> All users in this organization will be prevented from logging in</p>
              <button onClick={()=>{archiveOrganization(orgToArchive.id); setArchiveOrg('')}}> Archive </button>
            </div>
          </div>
        </div>
      )
    }
  }

  const restorePopup = () => {
    if (orgToRestore != '') {
      return (
        <div className='popup_overlay expanded'>
          <div className='popup_widget' style={{width: '500px'}}>
            <button id='fa-times' className='fa fa-times close' onClick={()=>{setRestoreOrg('')}}/>
            <div className='centered'>
              <h3> Restore Organization </h3>
              <p> Are you sure you want to restore {orgToRestore.name}?</p>
              <button onClick={()=>{restoreOrganization(orgToRestore.id); setRestoreOrg('')}}> Restore </button>
            </div>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    refetchOrgDomains();
  }, [domainAdded, domainDeleted]);

  async function archiveOrganization(org_id) {  
    try {
      await setOrganizationArchiveStatus({ org_id: org_id, org_status: 0 }).unwrap();
    } catch (error) {
      console.error('Error deleting organization:', error);
    }
  }

  async function restoreOrganization(org_id) {  
    try {
      await setOrganizationArchiveStatus({ org_id: org_id, org_status: 1 }).unwrap();
    } catch (error) {
      console.error('Error restoring organization:', error);
    }
  }
  
  async function handleAddDomain() {
    setIsValidDomainName(true);
    setDomainErrorMessage('');
    const sanitizedValue = newDomain.replace(/@/g, '');

    if (!sanitizedValue.includes('@') && domainRegex.test(sanitizedValue)) {
      setIsValidDomainName(true);
      try {
        await addDomain({ org_id: organization.id, domain: sanitizedValue }).unwrap();
        setNewDomain('');
      } catch (error) {
        var errorMessage = error.data.detail.match(/(?<=").+?(?=")/)[0];
        setDomainErrorMessage(`Error: ${errorMessage}`);
      }
    } else {
      setIsValidDomainName(false);
    }

  };

  const handleRemoveDomain = (domainId) => {
    deleteDomain({ domain_id: domainId }, {
      onSuccess: () => {
        refetchOrgDomains();
      },
      onError: (error) => {
        console.error('Error removing domain:', error);
      },
    });
  };

  return (
    <div style={{borderBottom: '1px solid #a9a9a9', padding: '20px'}}>
      {deletePopup()}
      {restorePopup()}
      <div>
        <div 
          style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}
          onMouseEnter={() => toggleArchive(organization.id, true)}
          onMouseLeave={() => toggleArchive(organization.id, false)}>
          <h2 style={{ fontWeight: '900', marginBottom: '10px', marginRight: '10px' }}>
              {organization.name}
          </h2>
          {organization.org_status == 1 ? 
            <i
              className='fa-solid fa-archive hover-point hidden'
              id={`archive_${organization.id}`}
              title='Archive Organization'
              style={{ fontSize: '18px' }}
              onClick={()=>{setArchiveOrg(organization)}}
            ></i>
          : 
            <i
              className='fa-solid fa-upload hover-point hidden'
              id={`archive_${organization.id}`}
              title='Restore Organization'
              style={{ fontSize: '18px' }}
              onClick={()=>{setRestoreOrg(organization)}}
            ></i>
          }
        </div>
        {organization.org_status == 1 &&
          <div onClick={() => setIsExpanded(!isExpanded)} style={{width: 'unset'}} className="hover-point">
            {isExpanded ? `-  Domains (${domainList?.length || '0'})` : `+ Domains (${domainList?.length || '0'})`}
          </div>
        }
        
      </div>
      {isExpanded && (
        <div>
          {isLoading ? (
            <p>Loading domains...</p>
          ) : (
            <>
            <div className="table" style={{marginLeft: '30px', marginTop: '10px'}}>
              {(domainList && domainList.length > 0) &&
                domainList.map(domain => (
                  <div className="row" key={domain.id}>
                    <div className="cell">
                      @{domain.domain}
                    </div>
                    <div className="right-cell">
                      <i className='fa-solid fa-trash hover-point' title='Delete' style={{ fontSize: '13px' }} onClick={() => handleRemoveDomain(domain.id)}></i>
                    </div>
                  </div>
                ))
              }
            </div>

            <div className="table" style={{marginLeft: '30px'}}>
              <div className="row" style={{display: "flex", alignItems: "center"}}>
                <div className="cell" style={{width: '240px'}}>
                  <span>@</span>
                  <input
                    type="text"
                    value={newDomain}
                    onChange={(e) => setNewDomain(e.target.value)}
                    placeholder="Add new domain"
                  />
                </div>
                <div className="cell">
                  <i className='fa-solid fa-square-plus hover-point' style={{marginRight: '5px'}} onClick={() => handleAddDomain()}></i>
                </div>
                <div className="cell domain-error">
                  {domainErrorMessage}
                  {!isValidDomainName && 'Invalid domain name'}
                </div>
              </div>
            </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

Organization.propTypes = {
  organization: PropTypes.object
}