import React, {useRef, useState, useEffect} from "react";
import PropTypes from "prop-types";
import ReportingTermsOnly from "../ReportingTermsOnly";
import ActivatedSummary from "./ActivatedSummary";
import { useNavigate } from "react-router-dom";
import { createSearchParams } from "react-router-dom";

export default function ActivatedStepTwo({callback, setSelectedStep, activationJSON, thisAudience, selectedStep}) {

  const agreeCheck = useRef();
  const scroll = useRef();

  const [checkEnabled, setCheckEnabled]  = useState(false);
  const [checkPreCheck, setCheckPreCheck] = useState(false);
  const navigate = useNavigate();

   const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 2;
    clearError(scroll);
    if (bottom) { 
      setCheckEnabled(true);
    }
  }

  useEffect(() => {
    console.log('checking ', activationJSON.activationAuthTimestamp, activationJSON);
    if (activationJSON.activationAuthTimestamp){
      console.log('setting precheck', checkPreCheck);
      setCheckPreCheck(true);
    }
  },[])

  const billingForm = () => {
      return (
        <div className='container p-1 sides-only'>
          <hr className='leftside w-100 reporting_hr'/>
          <div className='leftside'>
            <div className='terms-wrapper' ref={scroll} onScroll={(e) => handleScroll(e)}>
              <ReportingTermsOnly/>
            </div>
            <div className='billing-table'>
              <input type='checkbox' ref={agreeCheck} disabled={!checkEnabled} onChange={()=> {clearError(agreeCheck); setCheckPreCheck(!checkPreCheck);}} checked={checkPreCheck ? 'checked': ''}></input>
              <label>I agree to the usage and reporting terms</label>
            </div>
          </div>
        </div>
      )
  }

  const clearError = (t) => {
    t.current.className = t.current.className.replace(' error','');
  }

  const submitInfo = () => {
    //setBillingAndAuth = (billingContactName, billingEmailAddress, contactEmailAddress, authTimeStamp) => {
      if ( !agreeCheck.current.checked)
      {
          agreeCheck.current.className = agreeCheck.current.className + ' error';
      }
      else {
        callback(Date.now())
      }
  }

  return (
    <div className={selectedStep == 2 ? '' : 'hidden'}>
      <div className='container p-1 sides-only'>
        <h3 className='w-100'>Authorize and Confirm</h3>
        <hr className='leftside w-100'/>
      </div>
      <ActivatedSummary activationJSON={activationJSON} thisAudience={thisAudience} /> 
      {billingForm()}
      <div className='activation-footer'>
        <div className='leftside activation-cancel-button'
         onClick={() => {navigate({ pathname: '/dashboard/audience', search: createSearchParams({ uuid: thisAudience.uuid, cView: thisAudience.cluster, custom: thisAudience.custom }).toString() })}}
         >CANCEL</div>
        <div className='leftside activation-cancel-button ml-0' onClick={()=>{setSelectedStep(1)}}>BACK</div> 
        <div className='rightside activation-continue-button mr-2' onClick={()=>{submitInfo()}}>CONTINUE TO BILLING</div> 
      </div>
    </div>
  )
}

ActivatedStepTwo.propTypes = {
  callback: PropTypes.func,
  setSelectedStep: PropTypes.func,
  activationJSON: PropTypes.object,
  thisAudience: PropTypes.object,
  selectedStep: PropTypes.number,
}