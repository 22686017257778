import React, { useEffect } from "react";
import { useGetRoleQuery, useAllCategoriesQuery, useGetCountRequestsQuery } from "../app/api";
import ClientDashboard from "./ClientDashboard/ClientDashboard"
import AdminDashboard from "./AdminDashboard/AdminDashboard"
import { useSelector, useDispatch } from "react-redux";
import { selectUserName } from "../features/auth/authSlice";
import { save, selectOverride } from "../features/auth/roleSlice";
import { saveAudience } from "../features/audiences/audienceSlice";
//import PendingUser from "./PendingUser";
import { logout } from "../features/auth/authSlice";
import { saveCurrentView } from "../features/currentView/currentViewSlice";
import { selectUserRole, selectOrgId } from "../features/auth/authSlice";
import { selectUserId } from "../features/auth/authSlice";

export default function Dashboard() {

  const username = useSelector(selectUserName);
  const override = useSelector(selectOverride);
  const userRole = useSelector(selectUserRole);
  const userOrgId = useSelector(selectOrgId);
  console.log('org id', userOrgId);

  const userId = useSelector(selectUserId);

  const { data: roleResult, isSuccess: roleSuccess } = useGetRoleQuery({username: username});

  //const { archivedOrg } = useIsOrgArchivedQuery({orId: userOrgId});


  const dispatch = useDispatch();

  /* TODO: Change these to look at user role first */
  const { data: queriedCategories, isSuccess: catSuccess } =  useAllCategoriesQuery();
  const { data: openCount , isSuccess: countSuccess} =  useGetCountRequestsQuery({user_id: userId});

  /* Preload dataSlices from MYSQL here */
  useEffect(() => {
    if (roleSuccess) {
      dispatch(save({type: 'role', value: (roleResult || {role: ''}).role}));
      dispatch(save({type: 'id', value: (roleResult || {id: ''}).id}));
      dispatch(save({type: 'nameString', value: (roleResult || {first_name: '', last_name: ''}).first_name}));
    }
  }, [roleResult]);
  
  useEffect(() => {
    if (catSuccess) {
      dispatch(saveAudience({type: 'categories', value: queriedCategories}));
    }
  }, [queriedCategories]);
  
  useEffect(() => {
    if (countSuccess) {
      dispatch(saveAudience({type: 'open-requests', value: openCount}));
    }
  }, [openCount]);

  const whichDashboard = () => {

    if (roleResult && roleResult.role != userRole) {
      dispatch(saveCurrentView({type: 'logoutMessage', value: 'Your permissions have changed.  Please log in again.'}))
      dispatch(logout()); 
    }
    else {
      //if (org is archived) {
      //  dispatch(saveCurrentView({type: 'logoutMessage', value: 'Your organization has been archived.  Please contact your administrator.'}))
      //  dispatch(logout()); 
      //} else 
      if (userRole && (userRole.includes('ADMIN') && override === 'NO')) {
        return <AdminDashboard/>
      }
      else {
        if ( (userRole === 'CLIENT')|| (userRole.includes('ADMIN') && override === 'YES' )) {
          return (
            <>
              <ClientDashboard/>
            </>
          )
        }
        else {
          dispatch(saveCurrentView({type: 'logoutMessage', value: 'Your permissions are pending.  Thank you for your patience.'}))
          dispatch(logout()); 
        }
      }
    } 
  
  
  }

  // if roleResult.role != userRole :  please log out and log back in again
  // maybe force log out

  return (
    <div key={override}>
      {whichDashboard()} 
    </div>
  )
}